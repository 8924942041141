import { defineStore } from 'pinia'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    selected: '',
  }),

  persist: {
    storage: persistedState.sessionStorage,
    paths: ['selected'],
  },

  actions: {
    async fetches(params: any) {
      return await useApi<any>(createUrl('/users', {
        query: params,
      }))
    },

    async update(data: any) {
      return await $api(`/users/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async avatarUpload(data: any) {
      return await $api('/users/avatar', {
        method: 'POST',
        body: data,
      })
    },

    async avatarDelete(data: any) {
      return await $api('/users/avatar_delete', {
        method: 'POST',
        body: data,
      })
    },

    async changePassword(params: any) {
      return await $api('/users/change_password', {
        method: 'POST',
        body: params,
      })
    },

    async download(data: any) {
      return await $api('/users/download', {
        method: 'GET',
        params: data,
      })
    },

    async upload(data: any) {
      return await $api('/users/upload', {
        method: 'POST',
        body: data,
      })
    },
  },
})
