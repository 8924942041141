import revive_payload_client_4sVQNw7RlN from "/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/src/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Eorgdr12jA from "/src/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/src/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/src/.nuxt/element-plus-injection.plugin.mjs";
import plugin_8SbxDRbG6Y from "/src/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/src/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/src/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_AUP22rrBAc from "/src/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import index_D6fDksuOQB from "/src/plugins/casl/index.ts";
import index_kyvreQaQna from "/src/plugins/vuetify/index.ts";
import index_7axDEECAiz from "/src/plugins/i18n/index.ts";
import index_pJsEXjjc09 from "/src/plugins/iconify/index.ts";
import calendar_client_i1uzu9e7Tc from "/src/plugins/calendar.client.ts";
import directus_fdYmlw0WUJ from "/src/plugins/directus.ts";
import firebase_client_zXNDa0wxFH from "/src/plugins/firebase.client.ts";
import googleMap_ul9zF37YCH from "/src/plugins/googleMap.ts";
import layouts_mPotmY5lsV from "/src/plugins/layouts.ts";
import webfontloader_client_iX85i8j6U3 from "/src/plugins/webfontloader.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Eorgdr12jA,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_8SbxDRbG6Y,
  plugin_AOuQ1DYzjk,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  plugin_AUP22rrBAc,
  index_D6fDksuOQB,
  index_kyvreQaQna,
  index_7axDEECAiz,
  index_pJsEXjjc09,
  calendar_client_i1uzu9e7Tc,
  directus_fdYmlw0WUJ,
  firebase_client_zXNDa0wxFH,
  googleMap_ul9zF37YCH,
  layouts_mPotmY5lsV,
  webfontloader_client_iX85i8j6U3
]